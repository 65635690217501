<template>
	<div id="footer">
		<b-img src="/img/ubidata.svg" class="logo"></b-img>
		<span>Potenciado por Ubidata</span>
	</div>
</template>

<script>
export default {
	name: 'Footer'
}
</script>